import React from "react"
import cx from "classnames"
import { Link } from "gatsby"
import loadable from "@loadable/component"

import {
  ADVANTAGES,
  DEV_TECHNOLOGIES,
  OPTIONS_LIST,
  PROCESS_DESCRIPTION,
  STAGES,
  TITLES,
} from "../data/custom-software-development"
import { IMG } from "../const"
import Layout from "../components/layout"
import DesignerAndDev from "../components/tech-stack/designer-developer"
import "../assets/styles/commons/_common-kit.scss"
import Headline from "../components/headline"
import OptionsList from "../components/options-list"
import ServicesList from "../components/services-list"
import CaseStudies from "../components/case-studies"
import ColoredSection from "../components/colored-section"
import * as styles from "../assets/styles/custom-software-development.module.scss"

const TitleBlock = loadable(() => import("../components/title-block"))
const Accordion = loadable(() => import("../components/accordion"))
const ContactForm = loadable(() => import("../components/contact-form"))

const CustomSoftwareDevelopment = () => {
  return (
    <Layout
      pageTitle="Custom Software Development"
      defaultImage={`${IMG.THUMBNAILS}/thumbnail-custom-software-dev.jpg`}
    >
      {({
        width,
        handleOpenConfirmation,
        isOpenConfirmation,
        setConfirmationOption,
      }) => {
        return (
          <>
            <main className={cx(styles.customSoftwareDevBlock, "common-kit")}>
              <article>
                <Headline>
                  <div className={cx(styles.headline, "headline")}>
                    <div className={cx(styles.headline_grid, "headline_grid")}>
                      <p className={"strongText"}>
                        we bring ideas to life through
                      </p>
                      <h1 className={"withIndent"}>
                        <strong>Custom</strong> <br />
                        software development
                      </h1>
                      <div className={"rightColumn"}>
                        <p>
                          We develop custom software to meet your unique
                          requirements at a competitive cost, according to your
                          digital business goals.
                        </p>
                        <div className="onDesktop">
                          <Link
                            to="#contact-form"
                            className="goldFatLink customsoftware-talk"
                          >
                            Let’s talk
                          </Link>
                        </div>
                      </div>
                    </div>
                    <div className={styles.flexBlock}>
                      <div className={styles.strongTextBlock}>
                        <h3 className={styles.strongText}>
                          our digital product design service is for:
                        </h3>
                      </div>
                      <OptionsList list={OPTIONS_LIST} />
                    </div>

                    <div className="onMobile">
                      <Link
                        to="#contact-form"
                        className="goldFatLink customsoftware-talk"
                      >
                        Let’s talk
                      </Link>
                    </div>
                  </div>
                </Headline>
                <section className={cx(styles.processDescription, "section")}>
                  <TitleBlock options={TITLES.process_description} />
                  <div className={styles.processDescription__list}>
                    <OptionsList
                      list={PROCESS_DESCRIPTION}
                      mobileOrdered
                      small
                    />
                  </div>
                </section>

                <section className={cx(styles.advantages, "section")}>
                  <TitleBlock options={TITLES.advantages} />
                  <ServicesList services={ADVANTAGES.items} />
                </section>

                <section className={cx(styles.techStack, "section")}>
                  <TitleBlock options={TITLES.technologies} />
                  <DesignerAndDev developer={DEV_TECHNOLOGIES} />
                </section>

                <CaseStudies title={TITLES.case_studies} screenWidth={width} />

                <section className={cx(styles.how, "section")}>
                  <TitleBlock options={TITLES.structured} />
                  <div className={styles.accordionWrapper}>
                    <Accordion options={STAGES} />
                  </div>
                </section>

                <ColoredSection options={TITLES.colored} />
              </article>
            </main>
            <section className="footerForm common-kit">
              <div className="section" id="contact-form">
                <ContactForm
                  formStatID="CommonFooter"
                  handleOpenConfirmation={handleOpenConfirmation}
                  isOpenConfirmation={isOpenConfirmation}
                  setConfirmationOption={setConfirmationOption}
                />
              </div>
            </section>
          </>
        )
      }}
    </Layout>
  )
}

export default CustomSoftwareDevelopment
